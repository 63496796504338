<template>
  <div class="home">
    <!-- <div class="advertising">
       <div class="advertising-top setWidth">
         
    </div>
    </div> -->
     <Banner :imgBannerSrc='imgBannerSrc' :imgBannerLeftSrc='imgBannerLeftSrc' :atuoPlay='true'/>
    <div class="stepone">
      <div class="setBG">
    <div class="stepone-top setWidth">
              <h1>数字乡村大脑（普及版）</h1>
              <div>
                数字乡村大脑（普及版）是以村庄地缘关系为边界、以土地为纽带链接起乡村的生产、生活、经营、活动数据，以三务为核心的数字化服务平台。
                  以大数据为决策依据推动乡村振兴，发挥土地资源价值最大化，最终带动招商引资和乡村整体科学治理发展。
            </div>
            <el-button class="btn" @click="$router.push({path:'productsServe'})">了解更多</el-button>
      </div>
       
    </div>
    </div>
    <div class="steptwo">
      <div class="steptwo-top setWidth">
          <h1>
              数字乡村大脑（普及版）对乡村的价值
          </h1>
          <div>
            形成每个村自己的乡村旅游资源、农产品资源及特色资源的线上宣传平台
          </div>
          <div class="list">
                <div class="item">
                    <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2020%402x.png" alt="加载失败稍后重试">
                    <div class="title">
                      村书记
                    </div>
                    <div class="context">
                      掌握全村真实数据，
                      <br/>
                      利于对全村的管理决策
                    </div>
                    <div class="detailed">
                      100%掌握党建、人口、土地、财务、组织数据
                    </div>
                </div>
                <div>
                   <div class="item">
                    <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2023%402x.png" alt="加载失败稍后重试">
                    <div class="title">
                      村委工作人员
                    </div>
                    <div class="context">
                      围绕日常繁琐重复填表上报 
                      <br/>
                      等工作，解决低效工作输出
                    </div>
                    <div class="detailed">
                      工作效率提升一倍，时间节省一半
                    </div>
                </div>
                </div>
                <div>
                   <div class="item">
                    <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2022%402x.png" alt="加载失败稍后重试">
                    <div class="title">
                      村民
                    </div>
                    <div class="context">
                     有获得感、 满意感
                     <br/>
                    </div>
                    <div class="detailed">
                     民情处理、信息公开、生活服务
                    </div>
                </div>
                </div>
          </div>
      </div>
    
    </div>
    <div class="stepthree">
          <div class="stepthree-top setWidth">
                <h1 class="title">数字乡村大脑（普及版）</h1>
                <p>为省市/区县/乡镇/乡村管理者提供多层次服务</p>
                <div>为各级农业农村主管部门提供科学发展与治理能力，提升管理效率、构建公共服务</div>
                <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Group%207262%402x.png" alt="">
          </div>
    </div>
    <div class="stepfour">
          <div class="stepfour-top setWidth">
               <h1>深入乡村，精准共创</h1> 
                <p>与百余位街道/乡村的书记、主任、工作人员共创乡村科学化数字化管理和发展</p>
                <div class="img">
                    <div>
                        <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2080%402x.png" alt="fsfsf">
                        <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2081%402x.png" alt="fsfsf">
                    </div>
                    <div>
                        <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2082%402x.png" alt="fsfsf">
                        <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Mask%20Group%402x%281%29.png" alt="fsfsf">
                    </div>
                    <div>
                        <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2084%402x.png" alt="fsfsf">
                        <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2079%402x.png" alt="fsfsf">
                    </div>
                </div>
          </div>
    </div>
    <div class="stepfive">
      <div class="stepfive-top setWidth">
            <h1>应用案例</h1>
            <div>
                <div class="carousel">
                  <el-carousel ref="carousel" :autoplay='false' indicator-position="none" @change='changeCarousel' arrow="never">
                      <el-carousel-item v-for="item in sqdata" :key="item.src">
                        <img :src="item.src" alt="">
                      </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="carouselRight">
                      <span>{{sqdata[ind].title}}</span>
                      <p>{{sqdata[ind].context}}</p>
                      <div v-if="sqdata.length>1"><el-button type="success" :disabled='index==="01"' icon="el-icon-arrow-left" circle @click="switchImg('left')"></el-button><span>-{{index}}-</span><el-button type="success" :disabled='index==="0"+(sqdata.length)' icon="el-icon-arrow-right" circle @click="switchImg('right')"></el-button></div>
                      <div v-else></div>
                </div>
            </div>      
      </div>
    </div>
    <div class="stepsix">
      <div class="stepsix-top setWidth">
          <h1>应用成效</h1>
          <div class="list">
              <div class="item">
                <div class="num">
                  <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Line%2010%20%28Stroke%29.png" alt="">
                  <span>100%</span>
                </div>
                <div class="context">
                  党组织建设、党员信息管理效率
                </div>
              </div>
              <div class="item">
                <div class="num">
                   <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Line%2010%20%28Stroke%29.png" alt="">
                  <span>200%</span>
                </div>
                <div class="context">
                  人口等信息查询/导表效率
                </div>
              </div>
              <div class="item">
                <div class="num">
                  <img style="transform: rotateX(180deg);" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Line%2010%20%28Stroke%29.png" alt="">
                  <span>50%</span>
                </div>
                <div class="context">
                  资料存储到云端，丢失大幅下降
                </div>
              </div>
              <div class="item">
                <div class="num">
                   <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Line%2010%20%28Stroke%29.png" alt="">
                  <span>100%</span>
                </div>
                <div class="context">
                  财务报表图表化，可视化率
                </div>
              </div>
          </div>
      </div>
    </div>
    <div class="stepseven">
      <div class="stepseven-top setWidth">
        <h1>一线反馈</h1>
        <div class="list">
          <div class="item">
              <div class="video" @click="playVideo('https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/video/%E6%9D%8E%E6%95%8F.mp4')">
                <img class="video setBG" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/322e6d463e17e9cfd7dd6aba3b8cc90f.jpg" alt="">
                <img class="Shape" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Shape.png" alt="">
              </div>
            
            <!-- <video  src="https://img.cdn.aliyun.dcloud.net.cn/guide/uniapp/%E7%AC%AC1%E8%AE%B2%EF%BC%88uni-app%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D%EF%BC%89-%20DCloud%E5%AE%98%E6%96%B9%E8%A7%86%E9%A2%91%E6%95%99%E7%A8%8B@20200317.mp4" controls>
                您的浏览器不支持 video 标签。
            </video> -->
            <div class="title">
            综合办
            </div>
            <div class="context">
              办公室仓库管理的数字化应用
            </div>
          </div>
           <div class="item">
             <div class="video" @click="playVideo('https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/video/%E6%9D%A8%E5%87%A4.mp4')">
                <img class="video setBG" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/fb7b2199dbb0e27198484fa448d04301.jpg" alt="">
                <img class="Shape" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Shape.png" alt="">
             </div>
             
             
            <!-- <video class="video" src="https://img.cdn.aliyun.dcloud.net.cn/guide/uniapp/%E7%AC%AC1%E8%AE%B2%EF%BC%88uni-app%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D%EF%BC%89-%20DCloud%E5%AE%98%E6%96%B9%E8%A7%86%E9%A2%91%E6%95%99%E7%A8%8B@20200317.mp4" controls>
                您的浏览器不支持 video 标签。
            </video> -->
            <div class="title">
            财务部
            </div>
            <div class="context">
              村集体经济、专项资金管理的数字化应用
            </div>
          </div>
           <div class="item">
             
             <div class="video" @click="playVideo('https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/video/%E9%83%91%E7%BE%A4%E8%8B%B1.mp4')">
                <img  class="video setBG" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/5752841f0b5ddd691d7783cbc7c8c1cd.jpg" alt="">
                <img class="Shape" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Shape.png" alt="">
             </div>
            <!-- <video class="video" src="https://img.cdn.aliyun.dcloud.net.cn/guide/uniapp/%E7%AC%AC1%E8%AE%B2%EF%BC%88uni-app%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D%EF%BC%89-%20DCloud%E5%AE%98%E6%96%B9%E8%A7%86%E9%A2%91%E6%95%99%E7%A8%8B@20200317.mp4" controls>
                您的浏览器不支持 video 标签。
            </video> -->
            <div class="title">
            社群办
            </div>
            <div class="context">
              人口信息、村民管理的数字化应用
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="my"> 
          联系我们
      </div>
      <div class="img">
        <div class="item" >
          <div></div>
          <span class="details">
                <span></span>
                <span>157-8796-7845</span>
          </span>
        </div>
        <div class="item">
          <div></div>
          <span class="details">
                <span></span>
                <span>qutanlu@qutanlu.com</span>
          </span>
        </div>
         <div class="item" @click="toTop">
          <div></div>
        </div>
      </div>
      
    </div>
    <div class="Dialog" v-show="show">
        <video class="video" :src="videoSrc" controls autoplay="autoplay">
                您的浏览器不支持 video 标签。
        </video>
        <div class="shutDown" @click="shutDown">
          <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2085.png" alt="">
        </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner'
export default {
        name: 'Home',
        components:{
          Banner
        },
        data(){
          return{
            src:"https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E8%BD%AE%E6%92%AD",
            sqdata:[
              {
                src:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%BA%94%E7%94%A8%E6%A1%88%E4%BE%8B1',
                title:"成都市郫都区战旗村",
                context:'四川省成都市郫都区战旗村，是新时代乡村振兴示范村，入选首批20个全国乡村治理典型案例。结合数字乡村大脑（普及版），在乡村新发展与治理能力得到进一步的提升，遵循习总书记的嘱托，让战旗村在乡村振兴中继续“走在前列，起好示范”。'
              },
            {
                src:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Mask%20Group%402x%E7%9A%84%E5%89%AF%E6%9C%AC.png',
                title:"成都市郫都区安龙村",
                context:'四川省成都市安德街道安龙村是中国小微盆景发源地之一，安龙村通过党建引领，全面推进乡村自治、村务监管、平安治理、为民服务数字化、实现乡村“治理”变“智理”，被评为四川省实施乡村振兴战略工作示范村。'
              },
              {
                src:"https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Mask%20Group%402x%281%29%E7%9A%84%E5%89%AF%E6%9C%AC.png",
                title:"成都市郫都区邻城村",
                context:'四川省成都市郫都区三道堰镇邻城村，幅员面积3.422平方公里，耕地面积3358亩。常住人口6487人。全村大力发展花卉产业，每年花卉苗木交易5000余万元。曾获“省级卫生村”“区级健康村”“区级文明村”等荣誉。'
              },
             {
                src:"https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Mask%20Group%402x%282%29.png",
                title:"成都市郫都区留驾村",
                context:'四川省成都市郫都区唐昌镇留驾村，地处于成都市水源保护区，幅员面积4.38平方公里，常住人口3930人，户籍人口4247人。在党委政府的领导下，留驾村定位“幸福童年 快乐留驾”，结合柏木河蔬菜产业园组团，助力唐昌镇农业大公园建设，提高村民收入。'
              },
              {
                src:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Mask%20Group%402x%283%29.png',
                title:"成都市郫都区火花村",
                context:'四川省成都市郫都区唐昌镇火花村，火花村位于郫都区唐昌镇西北面，主要以鲜花、蔬菜、苗木及常规农作物种植为主。火花村还是成都市万亩蔬菜生产基地之一，是乡村振兴五村连片泛战旗区域规划的会客厅。'
              },
            ],
            imgBannerSrc:[
              "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/1.1.png",
              'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/2.2.png'
            ],
            imgBannerLeftSrc:[
              'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/1.png',  
              'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/2.png'
            ],
            index:'01',
            time:5000,
            timer:'',
            ind:0,
            show:false,
            videoSrc:''
         }
        },
        mounted () {
          this.Interval()
        },
        beforeDestroy() {
          document.getElementsByTagName("body")[0].style.overflow="auto"
              clearInterval(this.timer);
        },
        methods:{
          Interval(){
            this.timer=setInterval(()=>{
              this.$refs.carousel.next()
            },this.time)
          },
          changeCarousel(index){
            this.ind=index
            this.index='0'+(index+1)
           },
           switchImg(type){
              // console.log(this.$refs.carousel)
              if(type==='left'){
                clearInterval(this.timer);
                  this.$refs.carousel.prev()
                  this.Interval()
              }else{
                clearInterval(this.timer);
                  this.$refs.carousel.next()
                this.Interval()
              }
      },
      toTop(){
        var scrollToTop=setInterval(function() {
           var pos = window.pageYOffset;
        if ( pos >0 ) {
           window.scrollTo(0, pos - 20 );
        } else {
            window.clearInterval( scrollToTop );
        }
        });
      },
            playVideo(src){
              document.getElementsByTagName("body")[0].style.overflow="hidden"
              this.show=true
              this.videoSrc=src
            },
            shutDown(){
              document.getElementsByTagName("body")[0].style.overflow="auto"
              this.show=false
              this.videoSrc=''
            }
        }
}
</script>

<style lang="scss" scoped>

.Dialog{
  // overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4000;
  background: #0000007c;
  display: flex;
  justify-content: center;
  align-items: center;
  .video{
    width: 1370px;
    height: 770px;
  }
  .shutDown{
    cursor:pointer;
    position: fixed;
    right: 40px;
    top: 40px;
  }
}
.contact{
  position: fixed;
  right: 20px;
  top: 600px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  .my{
    margin-bottom: 5px;
    width: 70px;
    height: 70px;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #30C558;
    line-height: 21px;
    letter-spacing: 8px;
    -webkit-background-clip: text;
    text-align: center;
    padding: 16px 5px 9px 11px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 1px rgba(48, 197, 88, 0.30000001192092896);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
  }
  .img{
    .item{
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 1px rgba(48, 197, 88, 0.30000001192092896);
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        margin-bottom: 5px;
        position: relative;
        .details{
          position: absolute;
          min-width:300px;
          height: 70px;
          display: none;
          border-radius: 1px 1px 1px 1px;
          opacity: 1;
          right: 70px;
          bottom: 0;
          & span:nth-child(1){
            position: absolute;
            background: #30C558;
            border-radius: 4px;
            width: 30px;
            height: 30px;
            transform: rotate(45deg);
            top: 20px;
            right: 20px;
            z-index: 1999;
          }
          & span:nth-child(2){
            position: absolute;
            background: #30C558;
            border-radius: 4px;
            // width: 180px;
            height: 70px;
            top: 0px;
            right: 27px;
            z-index: 1999;
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 23px;
            line-height: 70px;
            text-align: center;
            padding: 0 7px;
            // -webkit-background-clip: text;
          }
        }
        div{
          width: 30px;
            height: 30px;
        }
        padding: 20px;
        &:nth-child(1){
          
          div{ 
            background:url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/lianxidianhua%402x%E7%9A%84%E5%89%AF%E6%9C%AC.png");
            background-size: 100% 100%;
            
            
          }
          &:hover{
            background: #30C558;
            .details{
              display: block;
            }
            div{
              background:url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/lianxidianhua%402x.png");
              background-size: 100% 100%;
            }
          }
        }
       &:nth-child(2){
          div{ 
            background:url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/youxiang%402x.png");
            background-size: 100% 100%;
          }
          &:hover{
            background: #30C558;
            .details{
              display: block;
            }
            div{
              background:url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/youxiang%402x%E7%9A%84%E5%89%AF%E6%9C%AC.png");
              background-size: 100% 100%;
            }
          }
        }
        &:nth-child(3){
          div{ 
            background:url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Frame%2085%402x.png");
            background-size: 100% 100%;
          }
          &:hover{
            background: #30C558;
            div{
              background:url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/%E5%9B%9E%E5%88%B0%E9%A1%B6%E9%83%A8%402x.png");
              background-size: 100% 100%;
            }
          }
        }
    }
    
  }
}
.stepone{ 
  // background: red;

background: #F6F8FF;
  
  .setBG{
    background: url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/%E7%BB%84%201%205%402x.png") no-repeat;
  background-size: 100% 100%;

  }
  .stepone-top{
    margin-top: 300px;
    margin:0 auto;
    padding: 80px 0;
    h1{
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 56px;
      text-align: center;

      margin: 0;
    }
    div{
      padding: 80px 0;
      font-size: 25px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #3C4251;
      line-height: 63px;
       text-align: center;
    }
    .btn{
      // font-size: 16px;
      width: 176px;
      height: 64px;
      background: #30C558;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 2px solid #30C558;
      display: block;
      font-size: 24px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 28px;
// -webkit-background-clip: text;
      margin: 0 auto;
    }
  } 
  
  
}
.steptwo{
    // background: red;
    .steptwo-top{
      margin: 0 auto;
      padding: 80px 0;
        h1{
          font-size: 48px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 56px;
  
          margin: 0;
          text-align: center;
        }
        &>div:nth-child(2){
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 48px;
  
          text-align: center;
          margin: 20px 0 60px 0;
        }
        .list{
          display: flex;
          .item{
            padding: 60px 38px;
            background: linear-gradient(270deg, #FAFBFF 0%, #F2F6FF 100%);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin-right: 20px;
            // &:last-child{
            //   margin-right: 0;
            // }
            img{
              width: 100px;
              height: 100px;
             
              margin: 0 auto ;
              display: block;
               margin-bottom: 40px;
            }
            .title{
               margin-bottom: 20px;
               font-size: 32px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 38px;
        
                text-align: center;
            }
            .context{
              width: 416px;
              height: 37px;
               margin-bottom: 40px;
               font-size: 22px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #3E3E3E;
                line-height: 26px;
        
                text-align: center;
            }
            .detailed{
              text-align: center;
                font-size: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
        
            }
          }
        }
    }
  }
.stepthree{
  // background: red;
  padding-top: 80px;

background: #F6F8FF;
  .stepthree-top{
    margin: 0 auto;
    font-size: 48px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 56px;
    -webkit-background-clip: text;
    h1{
      font-size: 48px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 56px;
      -webkit-background-clip: text;
      text-align: center;
        margin: 0;
    }
    p{
      margin: 0;
      text-align: center;
    }
    div{
      margin: 20px 0 60px;
      text-align: center;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
        -webkit-background-clip: text;
    }
    img{
      width: 709px;
      height: 717px;
      display: block;
      margin:0 auto;
    }
  }
}
.stepfour{
  // background: red;
    .stepfour-top{
      margin: 0 auto;
      padding: 80px 0;
      h1{
        font-size: 48px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 56px;
        -webkit-background-clip: text;
        margin: 0;
        text-align: center;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
        -webkit-background-clip: text;
        text-align: center;
      }
      .img{
        margin: 0 auto;
        display: flex;
        
        justify-content: space-between;
        & div:nth-child(1){
            width:  493px;
            img{
              &:first-child{
                
                 height: 493px;
              }
              &:last-child{
               height: 272px;
              }
            }
        }
         & div:nth-child(2){
            width:  622px;
            img{
              &:first-child{
                
                 height: 355px;
              }
              &:last-child{
               height: 410px;
              }
            }
        }
        & div:nth-child(3){
            width:  369px;
            img{
              &:first-child{
                
                 height: 493px;
              }
              &:last-child{
               height: 272px;
              }
            }
        }
        div{
          height: 785px;
          img{
            height: 100%;
            width: 100%;
            display: block;
            &:last-child{
              margin-top: 20px;
            }
          }
        }
      }
    }
}
.stepsix{
  // background: red;
  background: url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Group%207284%402x.png") no-repeat;
   background-size: 100% 100%;
  padding: 80px 0;
    .stepsix-top{
      margin: 0 auto;
      h1{
        font-size: 48px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 56px;
        -webkit-background-clip: text;
        margin-bottom: 122px;
        text-align: center;
      }
      .list{
        display: flex;
        justify-content: space-between;
        .item{
          
          .num{
            display: flex;
            justify-content: center;
            margin-bottom: 47px;
            font-size: 96px;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 64px;
            -webkit-background-clip: text;
            & :last-child{
              margin-left: 20px;
            }
          }
          .context{
            text-align: center;
            font-size: 24px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 26px;
              -webkit-background-clip: text;
          }
        }
      }
    }
}
.stepseven{
    // background: red;
    .stepseven-top{
      padding: 80px 0;
      margin: 0 auto;
      h1{
        font-size: 48px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 56px;
        -webkit-background-clip: text;
        text-align: center;
        margin: 0;
        margin: 0 auto;
      }
      .list{
        margin-top: 27px;
        display: flex;
        justify-content: space-between;
        position: relative;
        .item{
          .video{
            cursor:pointer;
            width: 487px;
            height: 297px;
            border-radius: 8px 8px 8px 8px;
            position: relative;
            background: #000000;
            .setBG{ 
              opacity: 0.6;
            }
           
          }
          .Shape{
            position:absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
          .title{
            font-size: 28px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3C4251;
            line-height: 33px;
            -webkit-background-clip: text;
            text-align: center;
            margin: 15px 0 10px;
          }
          .context{
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #939393;
            line-height: 23px;
            text-align: center;
            -webkit-background-clip: text;
          }
        }
      }
    }
}
.stepfive{
     
  // display: flex;
  // justify-content: center;
  // align-items: center;
padding: 80px 0;
background: #F6F8FF;
  .stepfive-top{
    margin: 0 auto;
     h1{
        font-size: 48px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 56px;
        -webkit-background-clip: text;
        text-align: center;
        margin: 0;
        margin-bottom: 40px;
      }
    &>div{
      display: flex;
    }
    // width: 1440px;
    // height: 405px;
    // display: flex;
    .carousel{
      // flex: 1;
        width: 1007px;
        // height: 625px;    
        img{
          width: 100%;
          height: 100%;
        }
    }
    .carouselRight{
      //  width: 100%;
      position: relative;
      background: #FFFFFF;
      padding: 80px 25px 0 25px ;
      width: 512px;
      height: 625px;
      box-sizing: border-box;
        span{
         font-size: 36px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 42px;
          -webkit-background-clip: text;
        }
        p{
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #3E3E3E;
          line-height: 48px;
          -webkit-background-clip: text;
        }
        div{
          position: absolute;
          bottom: 35px;
          left:25px;
        margin-top: 58px;
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
          width: 50px;
          height: 50px;
        }
        span{
          display: inline-block;
          font-size: 36px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #000000;
          line-height: 42px;
          -webkit-background-clip: text;
          text-align: center;
        }
      }
    }
  }

}
</style>
<style lang="scss" >
.el-carousel{
  height: 100%;
  .el-carousel__container{
    height: 100%;
    .el-carousel__item{
      .img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-carousel__arrow{
  width: 48px !important;
  height: 48px !important;
  background-color: rgba($color: #e8e8e8, $alpha: 0.4) !important;
  .el-icon-arrow-right,.el-icon-arrow-left{
    font-size: 28px !important;
  }
}
</style>