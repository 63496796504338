<template>
  <div class="about">
    <Banner :imgBannerSrc='imgBannerSrc' :imgBannerLeftSrc='imgBannerLeftSrc'/>
    <div class="aboutmetop setWidth">
      <div class="a-left">
        <div class="title">关于我们</div>
        <div class="desc">
          四川乡村战旗数字科技有限公司是乡村三务数字化服务商，提供乡村发展与治理能力、乡村工作效率、公共服务能力的产品、技术和运营的行业解决方案。 团队主要成员来自于阿里系、浙大系和海归系，具有丰富的互联网产品技术和运营经验。 创始人熊伟曾获得2009年度阿里巴巴总裁特别奖、2015 年国务院办公厅“双创之星”等荣誉， 受到袁家军书记的表彰。四川乡村战旗数字科技有限公司积极为乡村三务数字化赋能，服务广大乡村，助力乡村振兴。
        </div>
      </div>
    </div>
    <!-- <div class="culture setWidth">
      <div class="title">企业文化</div>
      <div class="c-content">
        <div :class="['c-content-item',{'active':index===activeIndex},{'clearActive':index!==activeIndex}]" v-for="(item,index) in cultureData" :key="index" :style="{'background-image' : 'url(' + item.imgSrc + ')'}" @mouseover="mouseover(index)">
            <div class="context">
              <span>{{item.title}}</span>
              <p>{{item.context}}</p>
            </div>
        </div>
      </div>
    </div> -->
    <div class="setBG">
 <div class="enterpriseCulture setWidth">
      <div class="a-left">
        <div class="title">企业文化</div>
        <div class="aboutmetop-context">
          <div class="img">
            <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/Mask%20Group.png" alt="">
          </div>
          <div class="setFilex">
          <div class="aboutmetop-bottom" >
            <div class="aboutmetop-title">
              <span>真实</span>
              <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/Group%20251.png" alt="">
            </div>
            <p>
              做人真实，做事实在；<br/> 有话当面说，对事不对人； <br/>能够虚心接受他人意见并改进。
            </p>
          </div>
          <div class="aboutmetop-bottom" >
            <div class="aboutmetop-title">
              <span>勇敢</span>
              <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/Group%20251.png" alt="">
            </div>
            <p>
               勇于创新，敢于突破敢于尝试； <br/>不局限在现有资源和眼界思考和看待问题；<br/>遇到问题敢于面对，积极主动寻找资源解决。
            </p>
          </div>
          <div class="aboutmetop-bottom" >
            <div class="aboutmetop-title">
              <span>信守承诺</span>
              <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/Group%20251.png" alt="">
            </div>
            <p>
               执行力坚定，说到做到不打折扣；<br/>值得被信任和依赖，不对任何人过度承诺； <br/>今日事今日毕，不因个人问题影响客户和团队的结果。
            </p>
          </div>
          <div class="aboutmetop-bottom" >
            <div class="aboutmetop-title">
              <span>成就他人</span>
              <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/Group%20251.png" alt="">
            </div>
            <p>
              与客户共成长，帮助客户取得好的结果和发展； <br/>平衡短期和长期的利益，积极维护公司的正当权益；<br/>与团队共成长，通过自身的努力给与团队积极正向的影响。
            </p>
          </div>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  
    <!-- 公司环境 -->
    <div class="environment setWidth">
      <div>
       <div class="e-i-left">
          <div class="i-left">
            <div class="titleD">
              <div class="title">
                公司环境
              </div>
                <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/newHomeImg/Group%207293%402x.png" alt="" class="e-img-top">   
            </div>
            <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/environment1%201.png" alt="" class="e-img-top">
          </div>
          <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/environment3%201.png" alt="" class="e-img-bottom">
        </div>
        <div class="e-i-right">
          <img src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/environment2%201.png" alt="" class="e-img-r-top">
        </div>
      </div>
       
    </div>
    <!-- 领导关怀 -->
    <!-- <div class="leadershipcare">
      <div class="title">领导关怀</div>
      <div class="l-box">
        <el-carousel arrow="always" indicator-position="none" :autoplay="false" :loop="false">
          <el-carousel-item v-for="(item, index) in leadershipcares" :key="index">
            <div class="l-itembox">
              <div class="l-item" v-for="(list, idx) in item.arr" :key="idx">
                <img :src="list.pic" :alt="list.desc">
                <div class="desc">{{list.desc}}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
  </div>
</template>
<script>
import Banner from '@/components/banner'
export default {
  components:{
    Banner
  },
  data() {
    return {
      cultures: [
        {
          title: '真实',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture4.png',
          desc: '做人真实，做事实在；<br/>有话当面说，对事不对人；<br/>能够虚心接受他人意见并改进'
        },
        {
          title: '勇敢',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture2.png',
          desc: '遇到问题敢于面对，积极主动寻找资源解决；<br/>勇于创新，不局限在现有资源和眼界思考和看待问题，<br/>敢于突破敢于尝试'
        },
        {
          title: '信守承诺',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture3.png',
          desc: '今日事今日毕，不因个人问题影响客户和团队的结果，不对任何人过度承诺；<br/>执行力坚定，说到做到不打折扣，<br/>值得被信任和依赖'
        },
        {
          title: '成就他人',
          pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/culture1.png',
          desc: '与客户共成长，帮助客户取得好的结果和发展，<br/>与团队共成长，通过自身的努力给与团队积极正向的影响；<br/>平衡短期和长期的利益，积极维护公司的正当权益'
        }
      ],
      leadershipcares: [
        {
          arr: [
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care1.png',
              desc: '浙江省委书记袁家军和熊伟亲切握手，祝贺获得双创之星'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care2.PNG',
              desc: '民建省委会副主委、杭州市委会主委郭清晔到公司进行考察调研工作'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care3.png',
              desc: '浙江省政协副主席、中国侨联副主席吴晶等领导莅临公司参观指导'
            }
          ]
        },
        {
          arr: [
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care4.png',
              desc: '杭州市委副书记，萧山区委书记佟桂莉，杭州市副市长陈卫强莅临公司参观指导'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care5.png',
              desc: '2020年5月15日，下城区政协副主席潘松萍等一行领导莅临趣探路考察走访。'
            },
            {
              pic: 'https://qutanlu-prod.oss-cn-shenzhen.aliyuncs.com/newWebsite/aboutme/care6.png',
              desc: '2020年4月15日，民建省委会副主委，杭州市委会主委郭清晔，省委统战部部位会议成员，干部处处长王高瞻等赴趣探路开展考研调研工作。'
            }
          ]
        }
      ],
      imgBannerSrc:[
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E5%88%87%E5%9B%BE/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC%E8%BD%AE%E6%92%AD",
      ],
      imgBannerLeftSrc:[
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/Group%2022%20%282%29.png",
      ],
      cultureData:[
        {
          title:'真实',
          context:"做人真实，做事实在； 有话当面说，对事不对人； 能够虚心接受他人意见并改进。",
          imgSrc:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96/1.png'
        },
        {
          title:'勇敢',
          context:"遇到问题敢于面对，积极主动寻找资源解决；勇于创新，敢于突破敢于尝试不局限在现有资源和眼界思考和看待问题。",
          imgSrc:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96/2.png'
        },
        {
          title:'信守承诺',
          context:"今日事今日毕，不因个人问题影响客户和团队的结果，不对任何人过度承诺；执行力坚定，说到做到不打折扣，值得被信任和依赖",
          imgSrc:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96/3.png'
        },
        {
          title:'成就他人',
          context:"与客户共成长，帮助客户取得好的结果和发展，与团队共成长，通过自身的努力给与团队积极正向的影响；平衡短期和长期的利益，积极维护公司的正当权益",
          imgSrc:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96/4.png'
        }
      ],
      activeIndex:0,
      time:null
    }
  },
  methods:{
    mouseover(index){
      clearInterval(this.time);
      this.time=setTimeout(() => {
        this.activeIndex=index
      }, 250);
        
    }
  }
}
</script>
<style lang="scss" scoped>
.about{
  min-width: 1440px;
}
.activeClassName {
  // color: red;
}
.aboutmetop {
  display: flex;
  margin: 0 auto;
  padding: 60px auto 30px;
  .a-left {
    // flex: 1;
    // margin-right: 83px;
    
    .title {
      padding: 80px 0 60px 0;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 50px;
    }
    .desc {
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 40px;
    }
    
  }
  .a-img, .a-img img {
    width: 593px;
    height: 680px;
  }
} 
.culture {
  margin: 0 auto;
  padding: 60px auto 30px;
  &>.title {
      padding: 80px 0 60px 0;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 50px;
  }
  .c-content {
   width:100%;
   height: 500px;
  //  background: red;
   display: flex;
   justify-content: flex-end;
  //  justify-content: space-between;
   .c-content-item{
     width: 208px;
     height: 500px;
    //  background: rosybrown;
     position: relative;
    //  flex: 1;
    // &:hover
   &.active{
     p{
       opacity: 1;
       transition: all 1s;
     }
     width: 416px;
     transition: all 0.3s;
     
   }
   &.clearActive{
      p{
       opacity: 0;
      //  transition: all 0.3s;
     }
     width: 208px;
     transition: all 0.3s;
   }
  //  &:nth-child(4){
     
  //  }
     .context{
       width: 90%;
       height:180px;
       background: rgba(0, 0, 0, 0.6000000238418579);
       position: absolute;
       bottom: 0;
       span{
         margin-top: 10px;
         margin-left: 20px;
         display: inline-block;
         padding: 1px;
         border-bottom: 4px solid; 
         font-size: 32px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 38px;
       }
       p{
         padding: 10px 20px 0;
         width: 377px;
          height: 72px;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 24px;
       }
     }
   }
   
  }
  
}
.setBG{
  margin-top: 80px;
  padding-bottom: 80px;
  background: #FBFBFC;
}
.enterpriseCulture{
  display: flex;
  margin: 0 auto;
  // height: 800px;
  .aboutmetop-context{
     .setFilex{
      //  width: 1000px;
      //  width: 100%;
       display: grid;
       grid-template-columns: repeat(2, 760px);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  // grid-template-columns: repeat(4, 100px);
      //  flex-wrap: wrap;
     }
     .img{
       img{
         width: 100%;
       }
       
     }
      .aboutmetop-bottom{
        margin-top: 20px;
        height: 160px;
        width: 520px;
        // background: red;
        p{
            width: 100%;
            margin-top: 20px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3D3D3D;
            line-height: 28px;
          }
        .aboutmetop-title{
          width: 100%;
          height: 45px;
          font-size: 32px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 38px;
          img{
            width: 128px;
            display: block;
            margin-top: 5px;
          }
          
        }
      }
    }
    .a-left {
      width: 100%;
    // flex: 1;
    // margin-right: 83px;
    
    .title {
      padding: 80px 0 60px 0;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121212;
      line-height: 50px;
    }
    }
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 12px 4px;
  margin: 0 16px;
}
/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 160px;
  height: 1px;
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  box-sizing: content-box;
  opacity: 1;
  &:hover {
    border: 1px solid #f0f0f0;
    background-color: #f0f0f0;
    opacity: 1;
  }
}
/deep/ .is-active.el-carousel__indicator--horizontal .el-carousel__button {
  width: 160px;
  height: 1px;
  border: 1px solid #121212;
  background-color: #121212;
  box-sizing: content-box;
  &:hover {
    border: 1px solid #121212;
    background-color: #121212;
    opacity: 1;
  }
}
.environment {
  // padding: 160px 0;
  &>div{
    margin: 0 auto;
    display: flex;
  }
  margin: 100px auto 160px;
  display: flex;
  height: 867px;
  // justify-content: space-between;
    .e-i-left {
      // padding-right: 20px;
      margin-right: 20px;
      width: 820px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .i-left{
        display: flex;
        justify-content: space-between;
        .titleD{
          position: relative;
          .title{
            position: absolute;
            left: 73px;
            bottom: 30px;
            font-size: 36px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 42px;
            -webkit-background-clip: text;
          }
        }
      }
      .e-img-top  {
        &:nth-child(1){
          width: 414px;
          height: 414px;
        }
        &:nth-child(2){
          width: 382px;
          height: 414px;
        }
        img{
            width: 100%;
            height: 100%;
          }
      }
      .e-img-bottom {
        width: 820px;
        height: 429px;
      }
    }
    .e-i-right {
      // padding-top: 65px;
      width: 676px;
      img{
        width: 100%;
        height: 100%;
      }
    }
}
.leadershipcare {
  padding-bottom: 160px;
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #121212;
    line-height: 50px;
  }
  .l-box {
    padding-top: 40px;
    width: 1220px;
    height: 330px;
    box-sizing: content-box;
  }
  .l-itembox {
    width: 1220px;
    height: 330px;
    display: flex;
    justify-content: space-around;
    .l-item {
      width: 390px;
      img {
        width: 390px;
        height: 260px;
      }
      .desc {
        padding-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #121212;
        line-height: 20px;
      }
    }
  }
}
/deep/ .el-carousel__arrow {
  top: 130px;
}
</style>