<template>
  <div class="serve">
    <Banner :imgBannerSrc='imgBannerSrc' :imgBannerLeftSrc='imgBannerLeftSrc'/>
    <div class="stepone setWidth">
      <div :class="['tabs',{'active':index===activeIndex}]" v-for="(item,index) in tabsData" :key="index" @click="setActiveIndex(index)">
        <img :src="index===activeIndex?item.checkImg:item.uncheckImg" alt="">
        <span>{{item.title}}</span>
        <div class="bottomBorder"><span></span></div>
      </div>
    </div>
    <div class="steptwo setWidth">
     <div class="threeService" v-show="activeIndex===0">
      <div class="threeServices-top">
            <div 
              :class="['threeServices-top-item',{'threeServicesActive':index===threeServicesIndex}]" 
              :style="{'border':index===threeServicesIndex?`2px solid ${item.color}4D`:''}" 
              v-for='(item,index) in threeServices' :key="index"
              @mouseover="setThreeServicesActive(index)"
              >
                <i class="iconfont" :style="{color:item.color}" :class="item.icon"></i>
                <h1 :style="{'border-bottom':`5px solid ${item.color}`}">{{item.title}}</h1>
                <h2>{{item.theme}}</h2>
                <p>{{item.content}}</p>
            </div>
      </div>
      <div class="threeServices-bottom">
              <div>
                <img draggable="false" v-show="threeServicesIndex===0" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/党务说明.png" alt="">
                <img draggable="false" v-show="threeServicesIndex===1" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/村务.png" alt="">
                <img draggable="false" v-show="threeServicesIndex===2" src="https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/财务.png" alt="">
              </div>
      </div>
     </div>
     <div class="map" v-show="activeIndex===1">
        <div class="mapServe">
              <h1>地图服务</h1>
              <p>以村庄地缘关系为边界，把乡村的地、物、人、景绘制到地图上，实现一个村一张图管理</p>
              <div class="banner">
                <el-carousel ref="mapCarousel" indicator-position="none" @change='changeMapCarousel' :autoplay='false'  arrow="never">
                  <el-carousel-item v-for="item in mapData" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="switch"><el-button type="success" :disabled='mapCarouselIndex==="01"' icon="el-icon-arrow-left" circle @click="switchImg('mapCarousel','left')"></el-button><span>-{{mapCarouselIndex}}-</span><el-button type="success" :disabled='mapCarouselIndex==="0"+(mapData.length)' icon="el-icon-arrow-right" circle @click="switchImg('mapCarousel','right')"></el-button></div>
        </div>
     </div>
     <div class="map" v-show="activeIndex===2">
        <div class="mapServe">
              <h1>驾驶舱</h1>
              <p>乡村所有数据以驾驶舱仪表盘般显示，简单易上手</p>
              <div class="banner">
                <el-carousel ref="cockpitCarousel" indicator-position="none" @change='changeCockpitCarousel' :autoplay='false'  arrow="never">
                  <el-carousel-item v-for="item in cockpitData" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="switch"><el-button type="success" :disabled='cockpitCarouselIndex==="01"' icon="el-icon-arrow-left" circle @click="switchImg('cockpitCarousel','left')"></el-button><span>-{{cockpitCarouselIndex}}-</span><el-button type="success" :disabled='cockpitCarouselIndex==="0"+(cockpitData.length)' icon="el-icon-arrow-right" circle @click="switchImg('cockpitCarousel','right')"></el-button></div>
        </div>
     </div>
     <div class="miniPsrogram" v-show="activeIndex===3">
       <div class="Psrogram">
          <div class="banner">
                <el-carousel ref="PsrogramCarousel" :autoplay='false' indicator-position="none" @change='changePsrogramCarousel' arrow="never">
                  <el-carousel-item v-for="item in psrogramData" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="title">
                <h1>小程序</h1>
                <p>面向全网的小程序设计为村民提供公共服务，向外招商引资、旅游服务、产品销售等</p>
                <div class="switch"><el-button type="success" :disabled='PsrogramCarouselIndex==="01"' icon="el-icon-arrow-left" circle @click="switchImg('PsrogramCarousel','left')"></el-button><span>-{{PsrogramCarouselIndex}}-</span><el-button type="success" :disabled='PsrogramCarouselIndex==="0"+(psrogramData.length)' icon="el-icon-arrow-right" circle @click="switchImg('PsrogramCarousel','right')"></el-button></div>
              </div>
       </div>
     </div>
    </div>
  </div>
</template>
<script>
import Banner from '@/components/banner'
export default {
  components:{
    Banner
  },
  data(){
    return{
      tabsData:[
        {
          title:'三务平台',
          checkImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%208.png',
          uncheckImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%209.png'
        },
        {
          title:'地图',
          checkImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%2012.png',
          uncheckImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%2016.png'
        },
        {
          title:'驾驶舱',
          checkImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%2015.png',
          uncheckImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%2017.png'
        },
        {
          title:'小程序',
          checkImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%2026.png',
          uncheckImg:'https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/%E4%BA%A7%E5%93%81%E4%B8%8E%E6%9C%8D%E5%8A%A1%E5%88%87%E5%9B%BE/Frame%2018.png'
        },
      ],
      threeServices:[
        {
          icon:'icon-a-Group56',
          title:'党务',
          theme:'乡村党建、三会一课、 党组织管理、党员数字化档案',
          content:'抓住“党建引领”这一关键，充分发挥乡村党组织的核心力量，发挥每一位党员的先锋模范作用，实现乡村振兴。',
          color:'#FF5B5B'
        },
        {
          icon:'icon-a-Group57',
          title:'村务',
          theme:'乡村土地数据入库、人口管理、 信息公开展示',
          content:'围绕乡村村务日常工作，解决乡村信息分散、无跟踪、关联低的问题，提高工作人员效率，为村民提供更可信的服务',
          color:'#30C558'
        },
        {
          icon:'icon-a-Group581',
          title:'财务',
          theme:'集体经济组织经营与管理、 专项资金记录',
          content:'集体经济经营好、资金管牢是乡村党组织领导力的有效保障，是乡村发展的重要支撑，并于与村民的福祉息息相关。',
          color:'#356DFF'
        }
      ],
      mapData:[//地图图片
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/地图2.png",
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/地图3.png",
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/地图1.png",
      ],
      cockpitData:[//驾驶舱图片
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/cockpit1.png",
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/cockpit2.png",
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/cockpit3.png",
      ],
      psrogramData:[//小程序图片
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/program1.png",
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/program2.png",
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/program3.png"
      ],
     imgBannerSrc:[
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/banner右图2.png",
        // require("@/assets/img/MaskGroup.png")
      ],
      imgBannerLeftSrc:[
        "https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/Group%2022%20%282%29.png",
      ],
      time:5000,
      activeIndex:0,
      threeServicesIndex:0,
      cockpitCarouselIndex:'01',
      mapCarouselIndex:'01',
      PsrogramCarouselIndex:'01',
      timer:{
        mapCarousel:'',
        cockpitCarousel:'',
        PsrogramCarousel:''
      }
    }
  },
  mounted () {
    this.Interval('mapCarousel')
    this.Interval('cockpitCarousel')
    this.Interval('PsrogramCarousel')
  },
  beforeDestroy() {
        clearInterval(this.timer['mapCarousel']);
        clearInterval(this.timer['cockpitCarousel']);
        clearInterval(this.timer['PsrogramCarousel']);
  },
  methods: {
    setActiveIndex(index){
      this.activeIndex=index
      this.threeServicesIndex=0
    },
    setThreeServicesActive(index){
      this.threeServicesIndex=index
    },
    changeCockpitCarousel(index){
        this.cockpitCarouselIndex='0'+(index+1)
    },
    changeMapCarousel(index){
      this.mapCarouselIndex='0'+(index+1)
    },
    changePsrogramCarousel(index){
        this.PsrogramCarouselIndex='0'+(index+1)
    },
    Interval(type){
        if(type==='mapCarousel'){
             this.timer.mapCarousel=setInterval(()=>{
                this.$refs.mapCarousel.next()
              },this.time)
        }else if(type==='cockpitCarousel'){
              this.timer.cockpitCarousel=setInterval(()=>{
                this.$refs.cockpitCarousel.next()
              },this.time)
        }else{
          this.timer.PsrogramCarousel=setInterval(()=>{
              this.$refs.PsrogramCarousel.next()
           },this.time)
        }
    },
    switchImg(type,direction){
        // console.log(this.$refs.carousel)
        if(direction==='left'){
            clearInterval(this.timer[type]);
            this.$refs[type].prev()
             this.Interval(type)
        }else{
            clearInterval(this.timer[type]);
            this.$refs[type].next()
            this.Interval(type)
        }
    },
    // scrollBtm () {
    //   window.scrollTo(0,2000);
    // }
  }
}
</script>
<style lang="scss" scoped>
.serve{
  min-width: 1440px;
}
  .stepone{
    // width: 1220px;
    margin: 80px auto 60px;
    display: flex;
    justify-content: space-between;
    .tabs{
      cursor:pointer;
      width: 245px;
      height: 78px ;
      font-size: 24px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.active{
        color: #30C558;
        .bottomBorder{
          span{
            background: #30C558;
          }
        }
      }
      &>img{
        width: 70px;
        height: 70px;
        margin-bottom: 4px;
      }
      .bottomBorder{
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 4px;
          background: #F1F2F6;
          span{
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 4px;
            // background: crimson;
          }
      }
      &>span{
        margin-right: 15px;
      }
    }
  }
  .steptwo{
    // width: 1220px;
    margin: 0 auto 148px;
    display: flex;
    justify-content: space-between;
    .threeService{
      width: 100%;
    }
    .threeServices-top{
      display: flex;
      justify-content: space-between;
      .threeServicesActive{
        box-shadow: 0px 10px 36px 1px rgba(0, 0, 0, 0.10000000149011612);
      }
      .threeServices-top-item{
        cursor:pointer;
          padding: 18px;
          width: 333px;
          height: 439px;
          background: #FFFFFF;
          
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 2px solid rgba(255, 91, 91, 0);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // align-items: ;
          .iconfont{
            font-size: 50px;
            // color:#FF5B5B
          }
          h1{
            width: 80px;
            height: 56px;
            font-size: 40px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 47px;
            // border-bottom: 5px solid #FF5B5B;
          }
          h2{
            width: 260px;
            height: 80px;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 40px;
          }
          p{
            width: 295px;
            height: 120px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 40px;
            
          }
      }
    }
    .threeServices-bottom{
      display: flex;
      justify-content: center;
      div{
        width: 800px;
        height: 445px;
        margin-top: 100px;
        // background: red;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .map{
      width: 100%;
      display: flex;
      justify-content: center;
      h1{
        width: 144px;
        height: 50px;
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 42px;
      }
      p{
        width: 624px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 19px;
        text-align: center;
      }
      .mapServe{
        // height: 486px;
        width: 757.89px;
        height: 698px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .banner{
          width: 100%;
          height: 486px;
          img{
            width: 100%;
            height: 100%;
          }
        }
       .switch{
          // margin-top: 65px;
          height: 40px;
          width: 150px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .button{
            background: #30C558;
            color: #fff;
          }
          span{
            display: inline-block;
            height: 29px;
            font-size: 24px;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.25);
            line-height: 28px;
          }
        }
      }
    }
    .miniPsrogram{
      width: 100%;
      display: flex;
      justify-content: center;
      .Psrogram{
          position: relative;
          width: 840px;
          height: 600px;
          background: url("https://village-prod.oss-cn-shenzhen.aliyuncs.com/website/img/小程序.png");
          background-size: 100% 100%;
              // border-radius: 33px;
          .banner{
            position: absolute;
            width: 263px;
            height: 590px;
            top: 4px;
            left: 73px;
            img{
            width: 100%;
            height: 100%;
          }
            .el-carousel{
               border-radius: 33px;
            }
          }
          .title{
              position: absolute;
                height: 230px;
                width: 400px;
                // background: red;
                top:173px;
                right: 44px;
                h1{
                  width: 108px;
                  height: 50px;
                  font-size: 36px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #000000;
                  line-height: 42px;
                }
                p{
                  width: 400px;
                  height: 80px;
                  font-size: 16px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #000000;
                  line-height: 40px;
                }
                div{
                    margin-top: 40px;
                    height: 40px;
                    width: 150px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .button{
                      background: #30C558;
                      color: #fff;
                    }
                    span{
                      display: inline-block;
                      height: 29px;
                      font-size: 24px;
                      font-family: DIN-Bold, DIN;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.25);
                      line-height: 28px;
                    }
                  }
          }
      }
    }
  }
</style>
<style lang="scss" >
.el-carousel{
  height: 100%;
  .el-carousel__container{
    height: 100%;
    .el-carousel__item{
      .img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-carousel__arrow{
  width: 48px !important;
  height: 48px !important;
  background-color: rgba($color: #e8e8e8, $alpha: 0.4) !important;
  .el-icon-arrow-right,.el-icon-arrow-left{
    font-size: 28px !important;
  }
}
</style>
