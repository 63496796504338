<template>
   <div class="banner">
     <div class="box setWidth">
       <div class="banxin">
        <div class="bannerLeft">
            <div class="imgDiv" v-show="isShow">
                <img v-for="(item,index) in imgBannerLeftSrc" :key="index" v-show="srcIndex===index" :class="{'active':srcIndex===index&&atuoPlay}" :src="item" alt="">
            </div> 
          <div class="buttonDiv" v-show="atuoPlay">
            <el-button type="success" :disabled='index==="01"' icon="el-icon-arrow-left" circle @click="switchImg('left')"/>
               <span>-{{index}}-</span>
            <el-button type="success" :disabled='index==="0"+(imgBannerSrc.length)' icon="el-icon-arrow-right" circle @click="switchImg('right')"/>
          </div>
        </div>
      </div>
      <div class="movebix">
        <el-carousel ref="carousel" :autoplay='false' indicator-position="none" @change='changeCarousel' :interval='time'  arrow="never">
        <el-carousel-item  v-for="item in imgBannerSrc" :key="item">
         <img  :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
      </div>
     </div>
    </div>
</template>

<script>
export default {
  props:{
    imgBannerSrc:{
      type: Array,
      required:true,
      default: ()=>[]
    },
    imgBannerLeftSrc:{
      type: Array,
      required:true,
      default: ()=>[]
    },
    atuoPlay:{
        type: Boolean,
    }
  },
  data(){
    return{
      time:5000,
      index:'01',
      isShow:true,
      srcIndex:0,
      timer: ""
    }
  },
  mounted(){
    this.Interval()
  },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods:{
      Interval(){
        this.timer=setInterval(()=>{
          this.$refs.carousel.next()
        },5000)
      },
      switchImg(type){
        if(type==='left'){
          clearInterval(this.timer);
            this.$refs.carousel.prev()
          this.Interval()
        }else{
          clearInterval(this.timer);
            this.$refs.carousel.next()
           this.Interval()
        }
      },
      changeCarousel(data){
         this.srcIndex=data
        this.index='0'+(data+1)
      }
    }
}
</script>

<style lang='scss' scoped >
.banner{
  // height: 646px;
  width: 100%;
  min-width: 1040px;
  z-index: 1;
  background-color: #FAFAFA;
  overflow: hidden;
  .box{
    position: relative;
    margin: 121px auto 0;
    height: 525px;
  }
  .banxin {
    width: 1040px;
    position: absolute;
    top: 170px;
    .bannerLeft{
      position: relative;
      width: 400px;
      height: 300px;
      .imgDiv {
          img{
            position: absolute;
            width: 400px;
            height: 195px;
          }
          .active{
            // animation: fade 10s 0s infinite;
            animation-name: fade; //规定需要绑定到选择器的 keyframe 名称。。
            animation-duration: 5s; //规定完成动画所花费的时间，以秒或毫秒计。
            animation-timing-function: linear; //规定动画的速度曲线。
            // animation-delay: 2s; //规定在动画开始之前的延迟。
            animation-iteration-count: 1; //规定动画应该播放的次数。
            animation-direction: normal; //规定是否应该轮流反向播放动画。
            z-index: 1;
          }
     }
      .buttonDiv{
        position: absolute;
        bottom: 0;
        margin-top: 65px;
        height: 40px;
        width: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // .button{
        //   // background: #30C558;
        //   // color: #fff;
        // }
        span{
          display: inline-block;
          height: 29px;
          font-size: 24px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.25);
          line-height: 28px;
        }
      }
    }
  }
  .movebix {
    position: absolute;
    width: 720px;
    height: 405px;
    right: 0px;
    top: 60px;
    img{
      width: 100%;
    }
  }
}
@keyframes fade {
      0% {
        opacity: 0;
      }
      15% {
        opacity: 0.50;
      }
      30% {
        opacity: 1;
      }
      45% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      55% {
        opacity: 1;
      }
      70%{
        opacity: 1;
      }
      85% {
        opacity: 0.50;
      }
      100% {
        opacity: 0;
      }
}
</style>
<style lang="scss" >
.el-carousel{
  height: 100%;
  .el-carousel__container{
    height: 100%;
    .el-carousel__item{
      .img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-carousel__arrow{
  width: 48px !important;
  height: 48px !important;
  background-color: rgba($color: #e8e8e8, $alpha: 0.4) !important;
  .el-icon-arrow-right,.el-icon-arrow-left{
    font-size: 28px !important;
  }
}
</style>
